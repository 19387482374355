import {IconDefinition} from '@fortawesome/fontawesome-common-types';

export const faBoobs = (<unknown>{
  prefix: 'fac',
  iconName: 'boobs',
  icon: [
    512,
    512,
    [],
    null,
    'M117.328,229.336c-23.562,0-42.672,19.094-42.672,42.656s19.109,42.672,42.672,42.672S160,295.555,160,271.992    S140.891,229.336,117.328,229.336z M117.328,293.336c-11.766,0-21.328-9.578-21.328-21.344s9.562-21.328,21.328-21.328    s21.328,9.562,21.328,21.328S129.094,293.336,117.328,293.336z' +
      'M117.328,261.336c-5.891,0-10.672,4.766-10.672,10.656s4.781,10.672,10.672,10.672S128,277.883,128,271.992    S123.219,261.336,117.328,261.336z' +
      'M378.656,122.664c-55.062,0-102.312,33.375-122.656,81c-20.344-47.625-67.609-81-122.672-81    C59.688,122.664,0,182.352,0,255.992s59.688,133.344,133.328,133.344c55.062,0,102.328-33.391,122.672-81.016    c20.344,47.625,67.594,81.016,122.656,81.016c73.641,0,133.344-59.703,133.344-133.344S452.297,122.664,378.656,122.664z     M212.531,335.195c-21.156,21.156-49.281,32.797-79.203,32.797s-58.047-11.641-79.188-32.797    c-21.156-21.156-32.812-49.281-32.812-79.203c0-29.906,11.656-58.031,32.812-79.188c21.141-21.156,49.266-32.812,79.188-32.812    s58.047,11.656,79.203,32.812c21.156,21.156,32.797,49.281,32.797,79.188C245.328,285.914,233.688,314.039,212.531,335.195z     M457.859,335.195c-21.156,21.156-49.281,32.797-79.203,32.797c-29.906,0-58.031-11.641-79.188-32.797    s-32.812-49.281-32.812-79.203c0-29.906,11.656-58.031,32.812-79.188c21.156-21.157,49.281-32.812,79.188-32.812    c29.922,0,58.047,11.656,79.203,32.812c21.156,21.156,32.797,49.281,32.797,79.188    C490.656,285.914,479.016,314.039,457.859,335.195z' +
      'M394.656,229.336c-23.562,0-42.656,19.094-42.656,42.656s19.094,42.672,42.656,42.672s42.672-19.109,42.672-42.672    S418.219,229.336,394.656,229.336z M394.656,293.336c-11.75,0-21.328-9.578-21.328-21.344s9.578-21.328,21.328-21.328    c11.766,0,21.344,9.562,21.344,21.328S406.422,293.336,394.656,293.336z' +
      'M394.656,261.336c-5.891,0-10.656,4.766-10.656,10.656s4.766,10.672,10.656,10.672s10.672-4.781,10.672-10.672    S400.547,261.336,394.656,261.336z',
  ],
}) as IconDefinition;

export const faPenis = (<unknown>{
  prefix: 'fac',
  iconName: 'penis',
  icon: [
    512,
    512,
    [],
    null,
    'm332.920464,310.220056l0,-178.51029c0,-0.118167 -0.034467,-0.229768 -0.034467,-0.347935c9.388868,-5.973976 15.179359,-13.477553 15.179359,-22.517295c0,-100.993024 -70.988666,-108.844536 -92.744369,-108.844536s-92.744369,7.851512 -92.744369,108.844536c0,9.039742 5.790491,16.543319 15.179359,22.517295c-0.006893,0.118167 -0.034467,0.229768 -0.034467,0.347935l0,178.575938c-53.506897,5.14681 -95.419024,48.297301 -95.419024,100.592571c0,55.754924 47.626791,101.11119 106.1659,101.11119c24.95426,0 48.584979,-8.160058 67.562625,-23.121258c18.364129,14.436015 41.884554,23.121258 67.500584,23.121258c58.539109,0 106.1659,-45.362831 106.1659,-101.11119c0,-52.735112 -42.629045,-96.108806 -96.777032,-100.658219zm-88.360139,-289.041982l0,28.405929c0,5.652301 4.811623,10.241102 10.75377,10.241102s10.75377,-4.588802 10.75377,-10.241102l0,-28.405929c39.085816,4.65445 60.489953,35.325238 60.489953,87.666461c0,5.48818 -24.968047,18.90665 -71.243723,18.90665s-71.243723,-13.41847 -71.243723,-18.90665c0,-52.347788 21.404137,-83.012012 60.489953,-87.666461zm78.964378,470.329187c-46.682389,0 -84.672148,-36.172098 -84.672148,-80.628986c0,-5.652301 -4.811623,-10.241102 -10.75377,-10.241102s-10.75377,4.588802 -10.75377,10.241102c0,23.948424 8.830499,45.940534 23.49285,63.28476c-14.876048,11.212694 -33.122989,17.344226 -52.383266,17.344226c-46.682389,0 -84.665255,-36.172098 -84.665255,-80.628986c0,-44.456888 37.982865,-80.628986 84.665255,-80.628986c5.935254,0 10.75377,-4.588802 10.75377,-10.241102l0,-179.232419c16.296097,4.930172 36.238825,7.457623 56.105724,7.457623s39.809628,-2.527452 56.105724,-7.457623l0,179.225855c0,5.652301 4.811623,10.241102 10.75377,10.241102c0.234377,0 0.448074,-0.052518 0.682451,-0.065648c0.227484,0.01313 0.448074,0.065648 0.682451,0.065648c46.682389,0 84.665255,36.172098 84.665255,80.628986c-0.006893,44.463452 -37.989759,80.635551 -84.679041,80.635551z',
  ],
}) as IconDefinition;
