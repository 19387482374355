import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {LoginPageComponent} from '../../pages/login-page/login-page.component';
import {SignupPageComponent} from '../../pages/signup-page/signup-page.component';
import {IonAvatar, IonIcon, IonItem, IonLabel, IonText, MenuController, ModalController} from '@ionic/angular/standalone';
import {addIcons} from 'ionicons';
import {logInOutline, personCircle} from 'ionicons/icons';

@Component({
  selector: 'cmn-user-login-list-item',
  templateUrl: './user-login-list-item.component.html',
  styleUrls: ['./user-login-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule, AsyncPipe, IonItem, IonAvatar, IonIcon, IonLabel, IonText],
  standalone: true,
})
export class UserLoginListItemComponent {
  readonly user$;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly menuController: MenuController,
    private readonly modalController: ModalController,
  ) {
    addIcons({personCircle, logInOutline});
    this.user$ = this.authenticationService.user$;
  }

  async openLoginModal(): Promise<void> {
    this.menuController.close();
    const loginPage = await this.modalController.create({component: LoginPageComponent});
    await loginPage.present();

    const {data} = await loginPage.onDidDismiss();
    if (data === 1) {
      const signupPage = await this.modalController.create({component: SignupPageComponent});
      await signupPage.present();
      await signupPage.onDidDismiss();
    }
  }
}
