import {Component} from '@angular/core';
import {IonButton, IonIcon} from '@ionic/angular/standalone';
import {addIcons} from 'ionicons';
import {moon, sunnyOutline} from 'ionicons/icons';

@Component({
  selector: 'cmn-switch-theme-button',
  templateUrl: './switch-theme-button.component.html',
  standalone: true,
  imports: [IonButton, IonIcon],
})
export class SwitchThemeButtonComponent {
  paletteToggle = true;

  constructor() {
    addIcons({sunnyOutline, moon});
  }

  toggleChange() {
    this.paletteToggle = !this.paletteToggle;
    document.documentElement.classList.toggle('ion-palette-dark', this.paletteToggle);
  }
}
