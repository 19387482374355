import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {catchError, EMPTY, from, Observable, switchMap} from 'rxjs';
import {AlertController} from '@ionic/angular/standalone';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private readonly alertController = inject(AlertController);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return from(
          this.alertController.create({
            header: 'Erreur réseau',
            subHeader: "Une erreur réseau s'est produite. Veuillez vérifier votre connexion puis recharger la page.",
            message: `Détails: ${error.message}`,
            buttons: ['OK'],
          }),
        ).pipe(
          switchMap(async (alert) => alert.present()),
          switchMap(() => {
            return EMPTY;
          }),
        );
      }),
    );
  }
}
