import {Injector, ModuleWithProviders, Optional} from '@angular/core';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {provideAppCheck} from '@angular/fire/app-check';
import {FirebaseOptions, getApp} from '@firebase/app';
import {AppCheck, CustomProvider, initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider} from '@firebase/app-check';
import {app} from 'firebase-admin';
import {FIREBASE_ADMIN} from './firebase-admin.token';

export const firebaseProviders = (
  firebaseAppName: string,
  firebaseOptions: FirebaseOptions,
  recaptchaV3SiteKey: string,
  recaptchaEnterprise = false,
): ModuleWithProviders<unknown>[] => [
  provideFirebaseApp(() => initializeApp(firebaseOptions, {name: firebaseAppName})),
  provideAppCheck(
    (injector: Injector): AppCheck => {
      const admin = injector.get<app.App>(FIREBASE_ADMIN, null);
      if (admin) {
        const provider = new CustomProvider({
          getToken: async () =>
            admin
              .appCheck()
              .createToken(firebaseOptions.appId, {ttlMillis: 604_800_000 /* 1 week */})
              .then(({token, ttlMillis: expireTimeMillis}) => ({token, expireTimeMillis})),
        });
        return initializeAppCheck(getApp(firebaseAppName), {provider, isTokenAutoRefreshEnabled: false});
      } else {
        const provider = recaptchaEnterprise
          ? new ReCaptchaEnterpriseProvider(recaptchaV3SiteKey)
          : new ReCaptchaV3Provider(recaptchaV3SiteKey);
        return initializeAppCheck(getApp(firebaseAppName), {provider, isTokenAutoRefreshEnabled: true});
      }
    },
    [new Optional(), FIREBASE_ADMIN],
  ),
];
