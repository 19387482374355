import {APP_INITIALIZER, Provider} from '@angular/core';
import {Apollo, APOLLO_OPTIONS} from 'apollo-angular';
import {apolloClientServiceFactory, ApolloConfigService, resolveApolloClientOptions} from '../services/apollo-config.service';

export const ApolloProviders: Provider[] = [
  ApolloConfigService,
  // Special config for Apollo to use Ionic persistant storage
  {
    provide: APP_INITIALIZER,
    useFactory: resolveApolloClientOptions,
    deps: [ApolloConfigService],
    multi: true,
  },
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloClientServiceFactory,
    deps: [ApolloConfigService, APP_INITIALIZER],
  },
  Apollo,
];
