import * as Sentry from '@sentry/angular-ivy';
import {VERSION} from '../../common/version';
import {environment} from './environments/environment';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {DatePipe} from '@angular/common';
import {TruncateTextPipe} from '../../common/pipe/truncate-text.pipe';
import {ApolloProviders} from './app/utils/apollo-providers';
import {provideRouter, Router, RouteReuseStrategy, withEnabledBlockingInitialNavigation} from '@angular/router';
import {IonicRouteStrategy, ModalController, PopoverController} from '@ionic/angular/standalone';
import {HTTP_INTERCEPTORS, provideHttpClient} from '@angular/common/http';
import {AppCheckInterceptor} from '../../common/services/app-check-interceptor.service';
import {HttpInterceptorService} from '../../common/services/http-interceptor.service';
import {APP_INITIALIZER, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {provideIonicAngular} from '@ionic/angular/standalone';
import {routes} from './app/routes';
import {IonicStorageModule} from '@ionic/storage-angular';
import {firebaseProviders} from '../../common/firebase/firebase-providers';
import {provideServiceWorker} from '@angular/service-worker';
import {register as registerSwiper} from 'swiper/element/bundle';

Sentry.init({
  dsn: 'https://ac7829d113d24f4ca1e9ac1148b927b4@o4504824450908160.ingest.sentry.io/4504824452415488',
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [environment.apiRestEndpoint, environment.apiGraphqlEndpoint],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: `${VERSION.version}`,
  environment: environment.env,
  sendClientReports: false,
  enabled: environment.env === 'production' || environment.env === 'staging',
});

registerSwiper();

const bootstrap = () => {
  bootstrapApplication(AppComponent, {
    providers: [
      DatePipe,
      TruncateTextPipe,
      ModalController,
      PopoverController,
      importProvidersFrom(
        IonicStorageModule.forRoot(),
        ...firebaseProviders(environment.firebaseAppName, environment.firebaseOptions, environment.recaptchaV3SiteKey, true),
      ),
      ...ApolloProviders,
      {provide: 'WINDOW', useFactory: () => (typeof window !== 'undefined' ? window : null)},
      {provide: 'Document', useFactory: () => (typeof window?.document !== 'undefined' ? window.document : null)},
      {provide: 'ENVIRONMENT', useValue: environment},
      {
        provide: RouteReuseStrategy,
        useClass: IonicRouteStrategy,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AppCheckInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true,
      },
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => (): void => void 0,
        deps: [Sentry.TraceService],
        multi: true,
      },
      provideHttpClient(),
      provideIonicAngular({mode: 'md'}),
      provideRouter(routes, withEnabledBlockingInitialNavigation()),
      provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000',
      }),
    ],
  });
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
