import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {Observable} from 'rxjs';
import {LoadingStateService} from '../../services/loading-state.service';
import {IonProgressBar} from '@ionic/angular/standalone';

@Component({
  selector: 'cmn-progress-bar',
  styleUrls: ['./progress-bar.component.scss'],
  templateUrl: './progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, IonProgressBar],
  standalone: true,
})
export class ProgressBarComponent {
  @Input() color: string;

  constructor(private readonly loadingStateService: LoadingStateService) {}

  get displayProgressBar$(): Observable<boolean> {
    return this.loadingStateService.loading$;
  }
}
