import {Routes} from '@angular/router';
import {AuthExternalProviders} from '../../../common/guards/auth-external-providers.guard';
import {AuthGuard} from '../../../common/guards/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    title: 'Index - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/main-list-page/main-list-page.component'),
  },
  {
    path: 'about',
    title: '👨🏼‍💻 À propos - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/about-page/about-page.component'),
  },
  {
    path: 'advanced-search',
    title: 'Recherche avancée - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/advanced-search-page/advanced-search-page.component'),
  },
  {
    path: 'categories',
    title: 'Catégories - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/categories-page/categories-page.component'),
  },
  {
    path: 'categories/:slug',
    loadComponent: async () => import('./components/pages/categories-list-page/categories-list-page.component'),
  },
  {
    path: 'books',
    title: '📚 Bréviaires - Référencement des bréviaires de nos collections',
    loadComponent: async () => import('./components/pages/books-page/books-page.component'),
  },
  {
    path: 'books/:slug',
    loadComponent: async () => import('./components/pages/book-item-page/book-item-page.component'),
  },
  {
    path: 'albums',
    title: '🎧 Albums - Référencement audio dans nos collections',
    loadComponent: async () => import('./components/pages/albums-page/albums-page.component'),
  },
  {
    path: 'albums/:slug',
    loadComponent: async () => import('./components/pages/album-item-page/album-item-page.component'),
  },
  {
    path: 'favorites',
    title: 'Favorites - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/favorites-page/favorites-page.component'),
    canActivate: [AuthGuard],
  },
  {
    path: 'paillardes/:slug',
    loadComponent: async () => import('./components/pages/paillarde-item-page/paillarde-item-page.component'),
  },
  {
    path: 'to-learn',
    title: 'A apprendre - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/to-learn-page/to-learn-page.component'),
    canActivate: [AuthGuard],
  },
  {
    path: 'user',
    title: 'Utilisateur - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/user-page/user-page.component'),
    canActivate: [AuthGuard],
  },
  {
    path: 'not-found',
    title: 'Contenu introuvable - Paillardes & Chants',
    loadComponent: async () => import('./components/pages/not-found-page/not-found-page.component'),
  },
  {
    path: 'privacy-policy',
    title: '👨🏼‍💻 Privacy policy',
    loadComponent: async () => import('../../../common/components/rgpd/rgpd.component'),
  },
  {
    path: 'reset-password',
    title: 'Reset Password',
    loadComponent: async () => import('../../../common/components/pages/reset-password-page/reset-password-page.component'),
  },
  {
    path: 'provider-redirect/:provider',
    canActivate: [AuthExternalProviders],
    loadComponent: async () => import('./components/pages/main-list-page/main-list-page.component'),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
