import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {catchError, EMPTY, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {FalucheEnvironment} from '../../../../faluche/src/app/model/faluche.environment';
import {PaillardesEnvironment} from '../../../../paillardes/src/app/model/paillardes-environment';
import {EMAIL_VALIDATION_ERRORS} from '../../../constants/validation-errors';
import {AuthenticationService} from '../../../services/authentication.service';
import {ToastService} from '../../../services/toast.service';
import {ValidationErrorsComponent} from '../../validation-errors/validation-errors.component';
import {
  AlertController,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText,
  IonTitle,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import {addIcons} from 'ionicons';
import {logoFacebook, logoGoogle, personAdd} from 'ionicons/icons';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IonItem,
    FormsModule,
    ReactiveFormsModule,
    ValidationErrorsComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonLabel,
    IonIcon,
    IonText,
    IonInput,
  ],
  standalone: true,
})
export class LoginPageComponent implements OnDestroy {
  loginError: string;
  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  readonly emailValidationErrors = EMAIL_VALIDATION_ERRORS;
  readonly thirdAuthProvidersActivated: boolean;
  private userSubscription: Subscription;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly modalController: ModalController,
    private readonly alertController: AlertController,
    private readonly toastService: ToastService,
    @Inject('ENVIRONMENT') private readonly environment: FalucheEnvironment | PaillardesEnvironment,
  ) {
    addIcons({logoFacebook, logoGoogle, personAdd});
    this.userSubscription = authenticationService.loggedInStatus$.subscribe((authenticated: boolean) => {
      if (authenticated) {
        this.dismiss();
      }
    });
    this.thirdAuthProvidersActivated = this.environment.featureFlipping.thirdPartyAuthentication;
  }

  login(): void {
    const {email, password} = this.loginForm.value;
    this.authenticationService
      .login(email, password)
      .pipe(
        tap(() => {
          this.modalController.dismiss();
          this.toastService.successLoggedIn();
        }),
        catchError((error) => {
          if (error) {
            this.loginError = 'Email ou mot de passe invalide';
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  signup(): void {
    this.modalController.dismiss(1);
  }

  loginWithGoogle(): void {
    this.authenticationService.googleLogin();
  }

  loginWithFacebook(): void {
    this.authenticationService.facebookLogin();
  }

  forgotPassword(): void {
    const email = this.loginForm.get('email').value;
    this.modalController.dismiss();
    this.authenticationService
      .forgotPassword(email)
      .pipe(
        tap(async () => {
          const alert = await this.alertController.create({
            header: 'Email envoyé',
            message: `Si vous possédez un compte. Un mail de réinitialisation vous à été envoyé.`,
            buttons: [
              {
                text: 'Ok',
                handler: () => {
                  this.modalController.dismiss();
                },
              },
            ],
          });
          await alert.present();
        }),
      )
      .subscribe();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  async presentAlertForgotPassword(): Promise<void> {
    const email = this.loginForm.get('email').value;
    const alert = await this.alertController.create({
      header: 'Mot de passe oublié',
      message: `Envoyer un mail de réinitialisation à ${email} ?`,
      buttons: [
        {
          text: 'Non',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Oui',
          handler: () => {
            this.forgotPassword();
          },
        },
      ],
    });
    await alert.present();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
