import {DOCUMENT} from '@angular/common';
import {ApplicationRef, Inject, Injectable, OnDestroy} from '@angular/core';
import {
  NoNewVersionDetectedEvent,
  SwUpdate,
  VersionDetectedEvent,
  VersionInstallationFailedEvent,
  VersionReadyEvent,
} from '@angular/service-worker';
import {AlertController} from '@ionic/angular/standalone';
import {concat, interval, Subject} from 'rxjs';
import {filter, first, takeUntil} from 'rxjs/operators';
import {VERSION} from '../version';

@Injectable({providedIn: 'root'})
export class PwaService implements OnDestroy {
  // TODO: Check si cette mécanique fonctionne bien sur Safari et firefox
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly swUpdates: SwUpdate,
    private readonly applicationRef: ApplicationRef,
    private readonly alertController: AlertController,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  init(): void {
    if (this.swUpdates?.isEnabled) {
      this.swUpdates.versionUpdates
        .pipe(
          filter(
            (
              event: VersionDetectedEvent | VersionInstallationFailedEvent | VersionReadyEvent | NoNewVersionDetectedEvent,
            ): event is VersionReadyEvent => event.type === 'VERSION_READY',
          ),
          takeUntil(this.destroy$),
        )
        .subscribe((event: VersionReadyEvent) => {
          console.log(event);
          this.promptUser();
        });

      const appIsStable$ = this.applicationRef.isStable.pipe(first((isStable: boolean) => isStable === true));
      const everyTenMinutes$ = interval(600000);
      const everyTenMinutesOnceAppIsStable$ = concat(appIsStable$, everyTenMinutes$);
      everyTenMinutesOnceAppIsStable$.pipe(takeUntil(this.destroy$)).subscribe(async () => this.swUpdates.checkForUpdate());
    }
  }

  async promptUser(): Promise<void> {
    const toast = await this.alertController.create({
      header: '✨Nouvelle version disponible',
      subHeader: `${VERSION.version}`,
      message: `Mise à jour de l'app. La page va se recharger...
      N'hésite pas à réinstaller l'app sur ton écran d'accueil ou à nous contacter si tu constates le moindre bug.`,
      backdropDismiss: false,
      buttons: ['OK'],
    });
    toast.onDidDismiss().then(async () => this.swUpdates.activateUpdate().then(() => this.document.location.reload()));
    await toast.present();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
