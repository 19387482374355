<ion-app>
  <cmn-progress-bar color="primary" />
  <ion-split-pane #splitPane contentId="menu-content">
    <ion-menu #menu contentId="menu-content" type="overlay">
      <ion-header>
        <ion-toolbar>
          <ion-title aria-level="1" class="fal-app-component__title" role="heading" routerLink="/" slot="start">Paillardes.app</ion-title>
          @if (!(splitPane.ionSplitPaneVisible | async)?.detail?.visible) {
            <cmn-switch-theme-button />
            <ion-button (click)="menu.close()" color="dark" fill="clear" slot="end">
              <ion-icon aria-label="Fermer menu" name="close-outline" size="large" slot="icon-only" />
            </ion-button>
          } @else {
            <cmn-switch-theme-button slot="end" />
          }
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <cmn-user-login-list-item />
            <ion-item button routerDirection="root" routerLink="" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="musical-notes-sharp" slot="start" />
              <ion-label>Paillardes</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/categories" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="albums" slot="start" />
              <ion-label>Catégories</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/books" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="book" slot="start" />
              <ion-label>Bréviaires</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/albums" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="disc" slot="start" />
              <ion-label>Albums</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/favorites" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="star" slot="start" />
              <ion-label>Favorites</ion-label>
            </ion-item>
            <ion-item button routerDirection="root" routerLink="/to-learn" routerLinkActive="selected">
              <ion-icon aria-hidden="true" color="dark" name="bookmarks" slot="start" />
              <ion-label>À apprendre</ion-label>
            </ion-item>
            <ion-item href="https://faluche.app">
              <ion-icon aria-hidden="true" slot="start" src="assets/img/faluche-icon.svg" />
              <ion-label>Faluche.app</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
        <div class="pail-app-component__side-menu-icon-container">
          <img
            alt="logo paillardes.app"
            class="pail-app-component__app-icon"
            height="256"
            ngSrc="assets/icons/icon.svg"
            priority
            width="256"
          />
        </div>
      </ion-content>
      <ion-footer>
        <ion-menu-toggle auto-hide="false">
          <ion-item (click)="openTutorialModal()" button>
            <ion-icon aria-hidden="true" color="primary" name="logo-pwa" slot="start" />
            <ion-label color="primary">Ajouter à l'accueil</ion-label>
          </ion-item>
          <ion-item (click)="openSubmissionLink()" button>
            <ion-icon aria-hidden="true" color="dark" name="push-sharp" slot="start" />
            <ion-label>Soumettre un chant</ion-label>
          </ion-item>
          <ion-item button lines="none" routerDirection="root" routerLink="/about" routerLinkActive="selected">
            <ion-icon aria-hidden="true" color="dark" name="help" slot="start" />
            <ion-label>À propos</ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="menu-content" />
  </ion-split-pane>
  @if (showFabContactButtons) {
    <cmn-fab-contact
      [facebookMessengerLink]="environment.facebookMessengerLink"
      [instagramDirectMessageLink]="environment.instagramDirectMessageLink"
      [mailLink]="environment.mailContact"
      [whatsAppLink]="environment.whatsAppLink"
    />
  }
</ion-app>
