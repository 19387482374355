<ion-header>
  <ion-toolbar>
    <ion-title>Connexion</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  @if (thirdAuthProvidersActivated) {
    <ion-item lines="none">
      <ion-label>
        <ion-button (click)="loginWithFacebook()" class="ion-padding-vertical" color="facebook" expand="block" fill="outline" size="large">
          <ion-icon aria-hidden="true" name="logo-facebook" slot="start" />
          Avec Facebook
        </ion-button>
        <ion-button (click)="loginWithGoogle()" class="ion-padding-vertical" color="danger" expand="block" fill="outline" size="large">
          <ion-icon aria-hidden="true" name="logo-google" slot="start" />
          Avec Google
        </ion-button>
      </ion-label>
    </ion-item>
    <ion-item>
      <ion-text class="login-page__or-text">
        <h3>- ou -</h3>
      </ion-text>
    </ion-item>
  }
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <ion-item>
      <ion-input formControlName="email" label="Email" labelPlacement="floating" type="email" />
    </ion-item>
    <cmn-validation-errors [formControlRef]="loginForm.get('email')" [validationErrors]="emailValidationErrors" />
    <ion-item>
      <ion-input
        (keyup.enter)="login()"
        formControlName="password"
        label="Mot de passe"
        labelPlacement="floating"
        minlength="6"
        type="password"
      />
    </ion-item>
    <ion-item lines="none">
      <ion-label>
        <ion-text color="danger">
          <div class="form-error">{{ loginError }}</div>
        </ion-text>
        <ion-button [disabled]="!loginForm.valid" color="success" expand="block" fill="outline" size="default" type="submit">
          Connexion
        </ion-button>
      </ion-label>
    </ion-item>
    <ion-text class="ion-text-center">
      <h3><em>----</em></h3>
    </ion-text>
    <ion-item lines="none">
      <ion-label>
        <ion-button (click)="signup()" color="secondary" expand="block" fill="outline">
          <ion-icon aria-hidden="true" name="person-add" slot="start" />
          Créer compte
        </ion-button>
      </ion-label>
      <ion-label class="ion-text-end">
        <ion-button (click)="presentAlertForgotPassword()" [disabled]="!loginForm.get('email').valid" color="secondary" fill="clear">
          Réinitialiser pass
        </ion-button>
      </ion-label>
    </ion-item>
  </form>
</ion-content>
