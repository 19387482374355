import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppCheck, AppCheckTokenResult, getToken} from '@angular/fire/app-check';
import {catchError, from, Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

@Injectable()
export class AppCheckInterceptor implements HttpInterceptor {
  constructor(private readonly appCheck: AppCheck) {}

  // App Check debug token :
  // https://console.firebase.google.com/u/0/project/paillardes-et-chants/appcheck/apps
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(getToken(this.appCheck)).pipe(
      // keepUnstableUntilFirst, // maybe uncomment when ngUniversal
      take(1), // See https://stackoverflow.com/a/60196923/828547.
      switchMap((token: AppCheckTokenResult | undefined) => {
        if (token) {
          request = request.clone({
            setHeaders: {'X-Firebase-AppCheck': token?.token},
          });
        }
        return next.handle(request);
      }),
      catchError((error: Error) => {
        console.error(error.message);
        return next.handle(request);
      }),
    );
  }
}
