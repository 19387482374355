let fbSDK = false;
declare const FB: any;

export const initFbSDK = (document: Document, window: Window, facebookPageId: string, facebookAppId?: string) => {
  if (!fbSDK) {
    const chatBox = document.getElementById('fb-customer-chat');
    chatBox.setAttribute('page_id', facebookPageId);
    chatBox.setAttribute('attribution', 'biz_inbox');
    const tag3 = document.createElement('script');
    tag3.src = 'https://connect.facebook.net/fr_FR/sdk/xfbml.customerchat.js';
    tag3.id = 'facebook-jssdk';
    // @ts-ignore
    window.fbAsyncInit = () => {
      FB.init({
        appId: facebookAppId,
        xfbml: true,
        version: 'v18.0',
      });
      fbSDK = true;
      FB.AppEvents.logPageView();
    };
    document.body.appendChild(tag3);
  }
};
