import {ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {IonButton, IonContent, IonIcon, IonLabel, IonSegment, IonSegmentButton, ModalController, Platform} from '@ionic/angular/standalone';
import {addIcons} from 'ionicons';
import {logoApple, logoChrome, logoFirefox} from 'ionicons/icons';

@Component({
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FontAwesomeModule, IonContent, IonSegment, IonSegmentButton, IonLabel, IonIcon, IonButton],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TutorialComponent {
  segment = 0;

  constructor(
    private readonly modalController: ModalController,
    private readonly platform: Platform,
  ) {
    addIcons({logoChrome, logoApple, logoFirefox});
    if (this.platform.is('ios')) {
      this.segment = 2;
    } else if (this.platform.is('android')) {
      // @ts-ignore
      if (typeof InstallTrigger !== 'undefined') {
        this.segment = 1;
      } else {
        this.segment = 0;
      }
    }
  }

  doNotShowAgainAndDismiss(): void {
    this.modalController.dismiss().then();
  }

  dismiss(): void {
    this.modalController.dismiss().then();
  }

  segmentChanged(event: Partial<CustomEvent>): void {
    this.segment = Number(event.detail.value);
    event.stopPropagation();
  }
}
