import {AfterViewInit, ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  IonApp,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import {environment} from '../environments/environment';
import {TutorialComponent} from './components/tutorial/tutorial.component';
import {FabContactComponent} from '../../../common/components/fab-contact/fab-contact.component';
import {SwitchThemeButtonComponent} from '../../../common/components/switch-theme-button/switch-theme-button.component';
import {UserLoginListItemComponent} from '../../../common/components/side-menu/user-login-list-item/user-login-list-item.component';
import {ProgressBarComponent} from '../../../common/components/progress-bar/progress-bar.component';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {FaConfig, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {faBook, fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {faBoobs, faPenis} from './icons';
import {PwaService} from '../../../common/services/pwa.service';
import {addIcons} from 'ionicons';
import {
  albums,
  book,
  bookmarks,
  closeOutline,
  cloudOfflineOutline,
  disc,
  help,
  logoPwa,
  musicalNotesSharp,
  pushSharp,
  star,
} from 'ionicons/icons';
import {initFbSDK} from '../../../common/utils/facebook-sdk-init';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'pail-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IonSplitPane,
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonIcon,
    IonContent,
    IonList,
    IonMenuToggle,
    IonItem,
    IonLabel,
    IonFooter,
    IonRouterOutlet,
    FabContactComponent,
    SwitchThemeButtonComponent,
    UserLoginListItemComponent,
    ProgressBarComponent,
    RouterLink,
    AsyncPipe,
    RouterLinkActive,
    NgOptimizedImage,
  ],
})
export class AppComponent implements AfterViewInit {
  readonly showFabContactButtons = !environment.featureFlipping.facebookChat;
  readonly environment = environment;

  constructor(
    @Inject('WINDOW') private readonly window: Window,
    @Inject('Document') private readonly document: Document,
    private readonly modalController: ModalController,
    private readonly faIconLibrary: FaIconLibrary,
    private readonly faConfig: FaConfig,
    private readonly pwaService: PwaService,
  ) {
    this.pwaService.init();
    this.faIconLibrary.addIconPacks(fas, far, fab);
    this.faConfig.defaultPrefix = 'fas';
    this.faConfig.fallbackIcon = faBook;
    this.faConfig.fixedWidth = true;
    this.faIconLibrary.addIcons(faBoobs, faPenis);
    addIcons({closeOutline, musicalNotesSharp, albums, book, disc, star, bookmarks, logoPwa, pushSharp, help, cloudOfflineOutline});
  }

  ngAfterViewInit(): void {
    if (environment.featureFlipping.facebookChat) {
      initFbSDK(this.document, this.window, environment.facebookPageId, environment.facebookAppId);
    }
  }

  openSubmissionLink(): void {
    this.window.open(environment.formSubmissionLink, '_blank');
  }

  async openTutorialModal(): Promise<void> {
    const tutorialModal = await this.modalController.create({component: TutorialComponent});
    return tutorialModal.present();
  }
}
