<ion-content fullscreen="true" scroll-y="false">
  <swiper-container class="pail-tutorial__slides" grabCursor="true" keyboard="{enabled: true}" lazy="true" pagination="true">
    <swiper-slide>
      <div class="pail-tutorial__slide-container">
        <img
          alt="logo-paillardes.app"
          class="pail-tutorial__icon swiper-lazy"
          height="183"
          loading="lazy"
          src="assets/icons/icon.svg"
          width="192"
        />
        <h2>Guide d'utilisation</h2>
        <p><b>paillardes.app</b> est une PWA (Progressive Web App)</p>
        <p>Cela signifie que tu peux l'ajouter à ton écran d'accueil et profiter de fonctionnalités hors ligne.</p>
        <h2>Comment faire ?</h2>
        <div class="pail-tutorial__hand-translate-container">
          <fa-icon class="pail-tutorial__hand-translate" icon="hand-point-up" size="5x" />
        </div>
      </div>
      <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
    <swiper-slide>
      <div class="pail-tutorial__slide-container">
        <ion-segment (ionChange)="segmentChanged($event)" [value]="segment" swipe-gesture="false">
          <ion-segment-button class="pail-tutorial__segment-button" value="0">
            <ion-label>Chrome</ion-label>
            <ion-icon name="logo-chrome" />
          </ion-segment-button>
          <ion-segment-button class="pail-tutorial__segment-button" value="1">
            <ion-label>Firefox</ion-label>
            <ion-icon name="logo-firefox" />
          </ion-segment-button>
          <ion-segment-button class="pail-tutorial__segment-button" value="2">
            <ion-label>iOs</ion-label>
            <ion-icon name="logo-apple" />
          </ion-segment-button>
        </ion-segment>
        @if (segment === 0) {
          <article class="pail-tutorial__article">
            <video class="pail-tutorial__video" autoplay loop muted playsinline>
              <source
                src="https://res.cloudinary.com/ledzaphter/video/upload/c_limit,h_900,q_auto:best/v1642495514/install-chrome-android_wreukq.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/ledzaphter/video/upload/c_limit,h_900,q_auto:best/v1642495514/install-chrome-android_wreukq.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </article>
        }
        @if (segment === 1) {
          <article class="pail-tutorial__article">
            <video autoplay class="pail-tutorial__video" loop muted playsinline>
              <source
                src="https://res.cloudinary.com/ledzaphter/video/upload/c_limit,h_900,q_auto:best/v1642495460/install-firefox-android_vq3kn1.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/ledzaphter/video/upload/c_limit,h_900,q_auto:best/v1642495460/install-firefox-android_vq3kn1.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </article>
        }
        @if (segment === 2) {
          <article class="pail-tutorial__article">
            <video autoplay class="pail-tutorial__video" loop muted playsinline poster="assets/install-safari-ios.jpg">
              <source
                src="https://res.cloudinary.com/ledzaphter/video/upload/c_limit,h_900,q_auto:best/v1642495403/install-safari-ios_intpit.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </article>
        }
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="pail-tutorial__slide-container">
        <h2>Mode persistant</h2>
        <picture>
          <source
            srcset="https://res.cloudinary.com/ledzaphter/image/upload/c_limit,h_400,q_auto:best/v1642495901/pail-cached_cgllyb.webp"
            type="image/webp"
          />
          <source
            srcset="https://res.cloudinary.com/ledzaphter/image/upload/c_limit,h_400,q_auto:best/v1642495901/pail-cached_cgllyb.jpg"
            type="image/jpeg"
          />
          <img
            alt="Exemple de chanson mise en cache"
            class="pail-tutorial__picture swiper-lazy"
            height="1"
            loading="lazy"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOsq6mrBwAE8QH5A52ECwAAAABJRU5ErkJggg=="
            width="1"
          />
        </picture>
        <p>Lorsque tu visites une chanson, celle-ci sera mise en cache.</p>
        <p>Elle sera dorénavant accessible hors ligne.</p>
        <ion-button (click)="doNotShowAgainAndDismiss()" class="pail-tutorial__button" color="medium" fill="clear">
          Ne plus me montrer le guide
        </ion-button>
        <ion-button (click)="dismiss()" class="pail-tutorial__button" color="primary" fill="outline"> Commencer à utiliser</ion-button>
      </div>
      <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
  </swiper-container>
</ion-content>
