import {inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {ModalController} from '@ionic/angular/standalone';
import {take, tap} from 'rxjs/operators';
import {LoginPageComponent} from '../components/pages/login-page/login-page.component';
import {SignupPageComponent} from '../components/pages/signup-page/signup-page.component';
import {AuthenticationService} from '../services/authentication.service';

export const AuthGuard: CanActivateFn = () => {
  const authService = inject(AuthenticationService);
  const modalController = inject(ModalController);

  return authService.loggedInStatus$.pipe(
    take(1),
    tap(async (loggedInStatus: boolean) => {
      if (!loggedInStatus) {
        const loginModal = await modalController.create({component: LoginPageComponent});
        await loginModal.present();

        const {data} = await loginModal.onDidDismiss();
        if (data === 1) {
          const signupModal = await modalController.create({component: SignupPageComponent});
          await signupModal.present();
        }
      }
    }),
  );
};
