@if (user$ | async; as user) {
  <ion-item button routerDirection="root" routerLink="/user" routerLinkActive="selected">
    @if (user.photoURL) {
      <ion-avatar slot="start">
        <img loading="lazy" src="{{ user.photoURL }}" alt="Avatar utilisateur" />
      </ion-avatar>
    } @else {
      <ion-icon aria-hidden="true" name="person-circle" slot="start" />
    }
    <ion-label>
      <ion-text color="success">
        <h4>{{ user.username }}</h4>
      </ion-text>
    </ion-label>
  </ion-item>
} @else {
  <ion-item button (click)="openLoginModal()">
    <ion-icon aria-hidden="true" color="dark" name="log-in-outline" slot="start" />
    <ion-label>Connexion</ion-label>
  </ion-item>
}